
// Lighten badge
@mixin badge-variant-light($bg) {
  color: $bg;
  background-color: rgba($bg, 0.18);

  &[href] {
    @include hover-focus {
      color: $bg;
      text-decoration: none;
      background-color: rgba($bg, 0.4);
    }
  }
}

// Outline badge

@mixin badge-variant-outline($bg) {
  color: $bg;
  //border: 1px solid $bg;
  border: 0;
  background-color: transparent;
  box-shadow: $badge-box-shadow rgba($bg, 1);

  &[href] {
    @include hover-focus {
      color: $bg;
      text-decoration: none;
      background-color: rgba($bg, 0.2);
    }
  }
}
