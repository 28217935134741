.white-link {
  color: rgba(255, 255, 255, .5);
  &:hover {
    color: rgba(255, 255, 255, .75);
  }
}

div.command-input-label {
  &::selection {
    background-color: transparent;
    color: #0f0;
  }
}
input.command-input {
  &::selection {
    background-color: #0f0;
    color: #000;
  }
}

.white-box-shadow-button {
  $white-box-shadow-button-color: #aab8c5;
  color: $white-box-shadow-button-color;
  box-shadow: $white-box-shadow-button-color 0px 0px 4px 2.41877e-09px;
  &.disabled {
    color: $white-box-shadow-button-color;
    background: none;
  }
  &:hover {
    color: $white-box-shadow-button-color;
    background-color: rgba(170, 184, 197, .1);
  }
  &:active {
    color: $white-box-shadow-button-color !important;
    background-color: rgba(170, 184, 197, .4) !important;
  }
  &:focus {
    color: $white-box-shadow-button-color !important;
    box-shadow: 0 0 0 0.2rem rgba(170, 184, 197, .2) !important;
  }
  //&:focus:active {
  //  color: $white-box-shadow-button-color !important;
  //  box-shadow: 0 0 0 0.2rem rgba(170, 184, 197, .2);
  //  color: $white-box-shadow-button-color !important;
  //}
}
